import React from "react"
import { chakra, Box, Flex, Text } from "@chakra-ui/react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import { Hero } from "@giraldomac/gatsby-theme-wvumedicine/src/components/elements"
import {
  Container,
  Layout,
  Section,
  Main,
} from "@giraldomac/gatsby-theme-wvumedicine/src/components/layout"

import { FlyingWVBg, Seo } from "../../../components/elements"

const ChakraImg = chakra(GatsbyImage)
const ChakraBG = chakra(FlyingWVBg)

const PatientStory = ({ data }) => {
  const page = data.datoCmsCancerPatientStory

  return (
    <Layout>
      <Seo title={page.fullName} />

      <Hero
        overflow="hidden"
        maxHeight={280}
        textAlign="center"
        bgGradient="linear(to-t, primary, primaryHover)"
        sx={{ color: "primaryHover" }}
      >
        <ChakraBG objectFit="cover" opacity={0.75} />
      </Hero>

      <Box mt={[-24, -24, -28]}>
        <Container>
          <Box width="full" mx="auto">
            <Box mx="auto" display="block" textAlign="center" width="full">
              <ChakraImg
                borderStyle="solid"
                borderWidth={[4, 5, 6]}
                borderRadius="full"
                boxShadow="lg"
                image={page.portrait.gatsbyImageData}
                objectFit="cover"
                objectPosition="50% 50%"
                borderColor="white"
                height={["140px", "180px", "220px"]}
                mx="auto"
                width={["140px", "180px", "220px"]}
              />
            </Box>
          </Box>

          <Box textAlign="center" mt={6}>
            <Box as="h2" mb={2}>
              {page.fullName}
            </Box>
          </Box>
        </Container>
      </Box>

      <Main>
        <Section id="story">
          <Container>
            {/* Story */}
            <Flex flexWrap="wrap" justifyContent="center">
              <Box width={["full", 4 / 5, 2 / 3]}>
                  <Box
                  dangerouslySetInnerHTML={{
                    __html:
                      page.contentNode.childMarkdownRemark.html
                  }}
                />
              </Box>
            </Flex>
          </Container>
        </Section>
      </Main>
    </Layout>
  )
}

export const query = graphql`
  query patientStoryPageQuery($slug: String!) {
    datoCmsCancerPatientStory(slug: { eq: $slug }) {
      id
      fullName
      contentIntro
      contentNode {
        childMarkdownRemark {
          html
        }
      }
      position
      slug
      meta {
        status
      }
      portrait {
        gatsbyImageData(
          layout: FULL_WIDTH
          imgixParams: { fit: "crop", crop: "focalpoint" }
        )
      }
      seo {
        description
        title
        image {
          url
        }
      }
    }
  }
`

export default PatientStory
